import createReactComponent from '../createReactComponent';
export default createReactComponent('freeze-column', 'IconFreezeColumn', [
  ['path', { d: 'M9 9.5l-6 6', key: 'svg-0' }],
  ['path', { d: 'M9 4l-6 6', key: 'svg-1' }],
  ['path', { d: 'M9 15l-5 5', key: 'svg-2' }],
  ['path', { d: 'M9 3v18', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
      key: 'svg-4',
    },
  ],
]);
