import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-pause', 'IconFlagPause', [
  [
    'path',
    {
      d: 'M13.536 15.029a4.987 4.987 0 0 1 -1.536 -1.029a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v8.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M17 17v5', key: 'svg-2' }],
  ['path', { d: 'M21 17v5', key: 'svg-3' }],
]);
