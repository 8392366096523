import createReactComponent from '../createReactComponent';
export default createReactComponent('escalator', 'IconEscalator', [
  [
    'path',
    {
      d: 'M19.5 5h-2.672a2 2 0 0 0 -1.414 .586l-8.414 8.414h-2.5a2.5 2.5 0 1 0 0 5h3.672a2 2 0 0 0 1.414 -.586l8.414 -8.414h1.5a2.5 2.5 0 0 0 0 -5z',
      key: 'svg-0',
    },
  ],
]);
