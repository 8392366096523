import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-search', 'IconFlagSearch', [
  [
    'path',
    {
      d: 'M12 14a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-3' }],
]);
