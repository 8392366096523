import createReactComponent from '../createReactComponent';
export default createReactComponent('file-type-docx', 'IconFileTypeDocx', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  ['path', { d: 'M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4', key: 'svg-1' }],
  [
    'path',
    { d: 'M2 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z', key: 'svg-2' },
  ],
  [
    'path',
    { d: 'M17 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0', key: 'svg-3' },
  ],
  [
    'path',
    {
      d: 'M9.5 15a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1 -3 0v-3a1.5 1.5 0 0 1 1.5 -1.5z',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M19.5 15l3 6', key: 'svg-5' }],
  ['path', { d: 'M19.5 21l3 -6', key: 'svg-6' }],
]);
