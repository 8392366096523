import createReactComponent from '../createReactComponent';
export default createReactComponent('eye-dollar', 'IconEyeDollar', [
  ['path', { d: 'M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13.193 17.924c-.39 .05 -.788 .076 -1.193 .076c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.005 0 5.592 1.394 7.761 4.181',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-3' }],
]);
