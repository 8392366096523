import createReactComponent from '../createReactComponent';
export default createReactComponent('escalator-down', 'IconEscalatorDown', [
  [
    'path',
    {
      d: 'M4.5 7h2.733a2 2 0 0 1 1.337 .513l9.43 8.487h1.5a2.5 2.5 0 1 1 0 5h-2.733a2 2 0 0 1 -1.337 -.513l-9.43 -8.487h-1.5a2.5 2.5 0 1 1 0 -5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 3v7', key: 'svg-1' }],
  ['path', { d: 'M15 7l3 3l3 -3', key: 'svg-2' }],
]);
