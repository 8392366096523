import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-bolt', 'IconFlagBolt', [
  [
    'path',
    {
      d: 'M14.673 15.36a4.978 4.978 0 0 1 -2.673 -1.36a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-2' }],
]);
