import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-share', 'IconFlagShare', [
  [
    'path',
    {
      d: 'M13.13 14.833a5.002 5.002 0 0 1 -1.13 -.833a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M16 22l5 -5', key: 'svg-2' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-3' }],
]);
