import createReactComponent from '../createReactComponent';
export default createReactComponent('file-type-sql', 'IconFileTypeSql', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M5 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4', key: 'svg-3' }],
  ['path', { d: 'M18 15v6h2', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M13 15a2 2 0 0 1 2 2v2a2 2 0 1 1 -4 0v-2a2 2 0 0 1 2 -2z',
      key: 'svg-5',
    },
  ],
  ['path', { d: 'M14 20l1.5 1.5', key: 'svg-6' }],
]);
