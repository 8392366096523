import createReactComponent from '../createReactComponent';
export default createReactComponent('file-type-php', 'IconFileTypePhp', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  ['path', { d: 'M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4', key: 'svg-1' }],
  ['path', { d: 'M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6', key: 'svg-2' }],
  ['path', { d: 'M17 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6', key: 'svg-3' }],
  ['path', { d: 'M11 21v-6', key: 'svg-4' }],
  ['path', { d: 'M14 15v6', key: 'svg-5' }],
  ['path', { d: 'M11 18h3', key: 'svg-6' }],
]);
