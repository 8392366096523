import createReactComponent from '../createReactComponent';
export default createReactComponent('gif', 'IconGif', [
  [
    'path',
    { d: 'M8 8h-2a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2v-4h-1', key: 'svg-0' },
  ],
  ['path', { d: 'M12 8v8', key: 'svg-1' }],
  ['path', { d: 'M16 12h3', key: 'svg-2' }],
  ['path', { d: 'M20 8h-4v8', key: 'svg-3' }],
]);
