import createReactComponent from '../createReactComponent';
export default createReactComponent('filter-pin', 'IconFilterPin', [
  [
    'path',
    {
      d: 'M12 20l-3 1v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-2' }],
]);
