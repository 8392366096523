import createReactComponent from '../createReactComponent';
export default createReactComponent('filter-cancel', 'IconFilterCancel', [
  [
    'path',
    {
      d: 'M12 20l-3 1v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v1.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M17 21l4 -4', key: 'svg-2' }],
]);
