import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-plus', 'IconFlagPlus', [
  [
    'path',
    {
      d: 'M14.433 15.315a4.978 4.978 0 0 1 -2.433 -1.315a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M16 19h6', key: 'svg-2' }],
  ['path', { d: 'M19 16v6', key: 'svg-3' }],
]);
