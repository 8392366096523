import createReactComponent from '../createReactComponent';
export default createReactComponent('filter-bolt', 'IconFilterBolt', [
  [
    'path',
    {
      d: 'M12.991 19.67l-3.991 1.33v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-1' }],
]);
