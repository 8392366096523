import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'fire-extinguisher',
  'IconFireExtinguisher',
  [
    [
      'path',
      {
        d: 'M12 7a4 4 0 0 1 4 4v9a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1v-9a4 4 0 0 1 4 -4z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 16h6', key: 'svg-1' }],
    ['path', { d: 'M12 7v-3', key: 'svg-2' }],
    ['path', { d: 'M16 5l-4 -1l4 -1', key: 'svg-3' }],
    ['path', { d: 'M12 4h-3a3 3 0 0 0 -3 3', key: 'svg-4' }],
  ],
);
