import createReactComponent from '../createReactComponent';
export default createReactComponent('filter-discount', 'IconFilterDiscount', [
  [
    'path',
    {
      d: 'M12.705 19.765l-3.705 1.235v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 21l5 -5', key: 'svg-1' }],
  ['path', { d: 'M21 21v.01', key: 'svg-2' }],
  ['path', { d: 'M16 16v.01', key: 'svg-3' }],
]);
