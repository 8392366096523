import createReactComponent from '../createReactComponent';
export default createReactComponent('globe-filled', 'IconGlobeFilled', [
  [
    'path',
    {
      d: 'M11 4a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M14.133 1.502a1 1 0 0 1 1.365 -.369a9.015 9.015 0 1 1 -10.404 14.622a1 1 0 1 1 1.312 -1.51a7.015 7.015 0 1 0 8.096 -11.378a1 1 0 0 1 -.369 -1.365z',
      fill: 'currentColor',
      key: 'svg-1',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M11 16a1 1 0 0 1 .993 .883l.007 .117v4a1 1 0 0 1 -1.993 .117l-.007 -.117v-4a1 1 0 0 1 1 -1z',
      fill: 'currentColor',
      key: 'svg-2',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M15 20a1 1 0 0 1 .117 1.993l-.117 .007h-8a1 1 0 0 1 -.117 -1.993l.117 -.007h8z',
      fill: 'currentColor',
      key: 'svg-3',
      strokeWidth: '0',
    },
  ],
]);
