import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-code', 'IconFlagCode', [
  [
    'path',
    {
      d: 'M13.41 14.973a4.991 4.991 0 0 1 -1.41 -.973a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-2' }],
  ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-3' }],
]);
