import createReactComponent from '../createReactComponent';
export default createReactComponent('folder-open', 'IconFolderOpen', [
  [
    'path',
    {
      d: 'M5 19l2.757 -7.351a1 1 0 0 1 .936 -.649h12.307a1 1 0 0 1 .986 1.164l-.996 5.211a2 2 0 0 1 -1.964 1.625h-14.026a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v2',
      key: 'svg-0',
    },
  ],
]);
