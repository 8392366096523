import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-minus', 'IconFlagMinus', [
  [
    'path',
    {
      d: 'M14.373 15.301a4.978 4.978 0 0 1 -2.373 -1.301a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v9',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M16 19h6', key: 'svg-2' }],
]);
