import createReactComponent from '../createReactComponent';
export default createReactComponent('file-type-html', 'IconFileTypeHtml', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  ['path', { d: 'M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4', key: 'svg-1' }],
  ['path', { d: 'M2 21v-6', key: 'svg-2' }],
  ['path', { d: 'M5 15v6', key: 'svg-3' }],
  ['path', { d: 'M2 18h3', key: 'svg-4' }],
  ['path', { d: 'M20 15v6h2', key: 'svg-5' }],
  ['path', { d: 'M13 21v-6l2 3l2 -3v6', key: 'svg-6' }],
  ['path', { d: 'M7.5 15h3', key: 'svg-7' }],
  ['path', { d: 'M9 15v6', key: 'svg-8' }],
]);
