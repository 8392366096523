import createReactComponent from '../createReactComponent';
export default createReactComponent('file-type-txt', 'IconFileTypeTxt', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-1' }],
  ['path', { d: 'M16.5 15h3', key: 'svg-2' }],
  ['path', { d: 'M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4', key: 'svg-3' }],
  ['path', { d: 'M4.5 15h3', key: 'svg-4' }],
  ['path', { d: 'M6 15v6', key: 'svg-5' }],
  ['path', { d: 'M18 15v6', key: 'svg-6' }],
  ['path', { d: 'M10 15l4 6', key: 'svg-7' }],
  ['path', { d: 'M10 21l4 -6', key: 'svg-8' }],
]);
