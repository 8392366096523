import createReactComponent from '../createReactComponent';
export default createReactComponent('eye-up', 'IconEyeUp', [
  ['path', { d: 'M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 18c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6c-.09 .15 -.18 .295 -.27 .439',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 22v-6', key: 'svg-2' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-3' }],
]);
