import createReactComponent from '../createReactComponent';
export default createReactComponent('eye-discount', 'IconEyeDiscount', [
  ['path', { d: 'M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 18c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 21l5 -5', key: 'svg-2' }],
  ['path', { d: 'M21 21v.01', key: 'svg-3' }],
  ['path', { d: 'M16 16v.01', key: 'svg-4' }],
]);
