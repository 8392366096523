import createReactComponent from '../createReactComponent';
export default createReactComponent('ghost-3', 'IconGhost3', [
  [
    'path',
    {
      d: 'M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 10h.01', key: 'svg-1' }],
  ['path', { d: 'M14 10h.01', key: 'svg-2' }],
]);
