import createReactComponent from '../createReactComponent';
export default createReactComponent('globe-off', 'IconGlobeOff', [
  [
    'path',
    {
      d: 'M7.353 7.355a4 4 0 0 0 5.29 5.293m2.007 -2.009a4 4 0 0 0 -5.3 -5.284',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M5.75 15a8.015 8.015 0 0 0 9.792 .557m2.02 -1.998a8.015 8.015 0 0 0 -2.562 -11.559',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M11 17v4', key: 'svg-2' }],
  ['path', { d: 'M7 21h8', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
