import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-cancel', 'IconFlagCancel', [
  [
    'path',
    {
      d: 'M13.342 14.941a4.993 4.993 0 0 1 -1.342 -.941a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M17 21l4 -4', key: 'svg-3' }],
]);
