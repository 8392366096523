import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-down', 'IconFlagDown', [
  [
    'path',
    {
      d: 'M14.434 15.315a4.978 4.978 0 0 1 -2.434 -1.315a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M19 16v6', key: 'svg-2' }],
  ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-3' }],
]);
