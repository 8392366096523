import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-exclamation', 'IconFlagExclamation', [
  [
    'path',
    {
      d: 'M15.035 15.408a4.98 4.98 0 0 1 -3.035 -1.408a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M19 16v3', key: 'svg-2' }],
  ['path', { d: 'M19 22v.01', key: 'svg-3' }],
]);
