import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-pin', 'IconFlagPin', [
  [
    'path',
    {
      d: 'M12.857 14.675a5.016 5.016 0 0 1 -.857 -.675a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-3' }],
]);
