import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-cog', 'IconFlagCog', [
  [
    'path',
    {
      d: 'M12.901 14.702a5.014 5.014 0 0 1 -.901 -.702a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v6.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M19.001 15.5v1.5', key: 'svg-3' }],
  ['path', { d: 'M19.001 21v1.5', key: 'svg-4' }],
  ['path', { d: 'M22.032 17.25l-1.299 .75', key: 'svg-5' }],
  ['path', { d: 'M17.27 20l-1.3 .75', key: 'svg-6' }],
  ['path', { d: 'M15.97 17.25l1.3 .75', key: 'svg-7' }],
  ['path', { d: 'M20.733 20l1.3 .75', key: 'svg-8' }],
]);
