import createReactComponent from '../createReactComponent';
export default createReactComponent('flag-up', 'IconFlagUp', [
  [
    'path',
    {
      d: 'M14.138 15.241a4.979 4.979 0 0 1 -2.138 -1.241a5 5 0 0 0 -7 0v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 21v-7', key: 'svg-1' }],
  ['path', { d: 'M19 22v-6', key: 'svg-2' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-3' }],
]);
